<template>
  <v-dialog
    :value="true"
    width="500"
    persistent
    scrollable
    :disabled="saving || loading"
  >
    <v-card :loading="loading">
      <v-system-bar v-if="isNew"
        >+ Schüler*in {{ item.schoolYear.description }}</v-system-bar
      >
      <v-system-bar v-else>Schüler*in bearbeiten</v-system-bar>
      <v-card-text class="pa-0">
        <v-list :disabled="loading || saving">
          <v-list-item v-if="isNew"
            ><PersonInput
              v-model="item.student"
              group="student"
              label="Schüler*in"
          /></v-list-item>
          <PersonItem v-else :value="item.student" class="mb-2" />

          <v-list-item class="mt-2">
            <v-textarea
              outlined
              v-model="item.title"
              label="Titel"
              counter="100"
            ></v-textarea>
          </v-list-item>
          <v-list-item
            ><PersonInput
              v-model="item.firstCoach"
              group="teacher"
              label="Erstbetreuung"
              clearable
          /></v-list-item>
          <v-list-item
            ><PersonInput
              v-model="item.secondCoach"
              group="teacher"
              label="Zweitbetreuung"
              clearable
          /></v-list-item>
          <v-list-item
            ><PersonInput
              v-model="item.assessor"
              group="teacher"
              label="Zweitbeurteilung"
              clearable
          /></v-list-item>

          <v-row no-gutters>
            <v-col>
              <v-list-item
                ><DateInput
                  v-model="item.presentationDate"
                  label="Datum Präsentation"
                  clearable
              /></v-list-item>
            </v-col>
            <v-col>
              <v-list-item
                ><TimeInput
                  v-model="item.presentationTime"
                  label="Zeit Präsentation"
                  clearable
              /></v-list-item>
            </v-col>
          </v-row>
          <v-list-item>
            <LookupValueInput
              v-model="item.presentationRoom"
              :items="rooms"
              label="Präsentation Zimmer"
            />
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="danger" text @click="trash()" :loading="trashing">
          löschen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="close(false)"> abbrechen </v-btn>
        <v-btn color="primary" text @click="save()" :loading="saving">
          speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateInput from "common/components/DateInput.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonItem from "@/components/PersonItem.vue";
import TimeInput from "common/components/TimeInput.vue";
export default {
  components: {
    DateInput,
    LookupValueInput,
    PersonInput,
    PersonItem,
    TimeInput,
  },
  props: ["id", "schoolYear"],
  data() {
    return {
      loading: false,
      rooms: [],
      item: {
        student: {},
        firstCoach: {},
        secondCoach: {},
        assessor: {},
        schoolYear: this.schoolYear,
      },
      saving: false,
      trashing: false,
    };
  },
  methods: {
    close(update) {
      this.$router.push({ name: "ThesisList", params: { update: update } });
    },
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: "thesis/thesis",
        id: this.id,
      });
      this.rooms = await this.apiList({
        resource: "common/room",
      });
      this.loading = false;
    },
    async save() {
      this.saving = true;
      if (this.isNew) {
        await this.apiPost({
          resource: "thesis/thesis",
          data: this.item,
        });
      } else {
        await this.apiPut({
          resource: "thesis/thesis",
          data: this.item,
        });
      }
      this.close(true);
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: "Soll dieser Maturaarbeitseintrag gelöscht werden?",
          color: "danger",
          icon: "mdi-trash-can",
        })
      ) {
        this.trashing = true;
        await this.apiDelete({
          resource: "thesis/thesis",
          id: this.item.id,
        });
        this.close(true);
      }
    },
  },
  computed: {
    isNew() {
      return !this.item || !this.item.id;
    },
  },
  async created() {
    if (this.id > 0) {
      this.fetchData();
    }
  },
};
</script>
